import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", {
    attrs: {
      "title": "Add Storage Grades"
    }
  }, [_c("select-input", {
    key: "warehouseId",
    attrs: {
      "label": "Warehouse",
      "placeholder": "Select a warehouse",
      "reference": "grower.common.ware-houses",
      "source": "id",
      "rules": "required",
      "source-label": "description",
      "span": 12
    }
  }), _c("text-input", {
    key: "name",
    attrs: {
      "label": "Storage Grade",
      "placeholder": "Type Storage Grade",
      "span": 12,
      "rules": "required|max:10"
    }
  }), _c("text-input", {
    key: "description",
    attrs: {
      "label": "Description",
      "placeholder": "Type Description",
      "span": 24,
      "rules": "max:30"
    }
  }), _c("text-input", {
    key: "maxStorageDays",
    attrs: {
      "label": "Max Storage Days",
      "rules": "integer",
      "placeholder": "0, 1, 2,...",
      "span": 24
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateStorageGrades"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateStorageGrades = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-storage-grades"
  }, [_c("resource", {
    attrs: {
      "name": "grower.storage-grades",
      "api-url": _vm.apiUrl,
      "create": _vm.CreateStorageGrades,
      "redirect-route": "/watties-grower/storage-grades"
    }
  })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateStorageGrades",
  data() {
    return {
      CreateStorageGrades,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
